import React from 'react';

import './CarouselItem.Module.css';

const CarouselItem = ({ imageUrl, isSuperAdmin, onChangeImage }) => {
	const handleClick = (event) => {
		event.stopPropagation(); // Evita que el evento se propague y cause conflictos con el widget
		onChangeImage();
	};

	return (
		<div className='carousel-item'>
			<img src={imageUrl} alt='Carousel Item' />
			{isSuperAdmin && (
				<div className='edit-icon' onClick={handleClick}>
					<i className='bi bi-pencil'></i>
				</div>
			)}
		</div>
	);
};

export default CarouselItem;

import React from 'react';
import Formulario1 from '../../components/formulario1/Formulario1';
import Formulario2 from '../../components/formulario2/Formulario2';
import Footer from '../../components/footer/Footer';
import GloboContacto from '../../components/globoContacto/GloboContacto';
import Loader from '../../components/loader/Loader';
import NavBar from '../../components/navBar/NavBar';
import YouTube from 'react-youtube';

import { useMediaQuery } from 'react-responsive';

import Stamp from '../../components/stamp/Stamp';
import scroll from '../../assets/imagenes/scroll-bar.png';

import { getProductos, getDatos } from '../../utils/productos/productos';
import './Home.Module.css';
import CarouselImg from '../../components/carousel/Carousel.jsx';

import { isAuthenticated } from '../../utils/usuario/usuario';
import { Toaster, toast } from 'react-hot-toast';
import { Button } from 'react-bootstrap';
import axios from 'axios';

import ReactMarkdown from 'react-markdown';

function Home() {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const [productos, setProductos] = React.useState([]);
	const [datosContacto, setDatosContacto] = React.useState([]);

	const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

	const [viewScroll, setViewScroll] = React.useState(false);

	const [loader, setLoader] = React.useState(true);

	const [video, setVideo] = React.useState('');
	const [isEditing, setIsEditing] = React.useState(false);
	const [newVideo, setNewVideo] = React.useState({});

	const videoId = video[0]?.url?.split('v=')[1];

	const opts = {
		width: '100%',
		heigth: '100%',
		playerVars: {
			// Opciones del reproductor, como el autoplay
			autoplay: false,
		},
	};

	React.useEffect(() => {
		setLoader(true);
		toast.remove();
		async function getProducts() {
			setProductos(await getProductos());
			setLoader(false);
		}
		getProducts();

		setTimeout(() => {
			setLoader(false);
		}, 1000);
		window.addEventListener('actualizar-producto', getProducts);
		return () => {
			window.removeEventListener('actualizar-producto', getProducts);
		};
	}, []);
	React.useEffect(() => {
		const cargarVideo = async () => {
			const { data } = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=VIDEO`);
			setVideo(data.imagenes);
		};
		cargarVideo();
	}, []);
	React.useEffect(() => {
		setLoader(true);
		toast.remove();
		async function obtenerDatos() {
			setDatosContacto(await getDatos());
			setLoader(false);
		}
		obtenerDatos();
	}, []);

	const handleEditVideo = () => {
		setIsEditing(true);
		setNewVideo({
			url: video[0]?.url,
			descripcion: video[0]?.descripcion,
		});
	};

	const handleCancelEditVideo = () => {
		setIsEditing(false);
	};

	const handleSaveVideo = async () => {
		try {
			// Envía la nueva URL y descripción en el cuerpo de la solicitud
			const response = await axios.put(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes/${video[0]._id}?url=${newVideo.url}`,
				{ descripcion: newVideo.descripcion },
				config
			);

			// Actualiza el estado del video con los datos actualizados
			setVideo([{ url: newVideo.url, descripcion: newVideo.descripcion }]);
			setIsEditing(false);
			toast.success('Video actualizado con éxito!');
		} catch (error) {
			toast.error('Hubo un problema al cambiar el video');
		}
	};

	const handleVideoInputChange = (event) => {
		const { name, value } = event.target;
		setNewVideo({ ...newVideo, [name]: value });
	};

	return (
		<>
			{loader ? (
				<Loader />
			) : (
				<div className='fondoHome'>
					<header>
						<NavBar />
					</header>
					<main className='mainHome'>
						<>
							<Toaster
								position='bottom-left'
								reverseOrder={false}
								toastOptions={{ className: 'fondoToast' }}
							/>
							<section className='sectionCarousel'>
								<CarouselImg />
							</section>
							<section className='cotizar'>
								<Button
									type='submit'
									className='btn btn-secondary py-3 cotizacion'
									href={`https://api.whatsapp.com/send?phone=${datosContacto?.telefono}&text=Hola,%20estoy%20interesado%20en%20sus%20productos.%20%20Quisiera%20asesoramiento.`}
									target='_blank'
								>
									SOLICITAR COTIZACION
								</Button>
							</section>
							{isMobile && (
								<section className='sectionScroll'>
									<img src={scroll} alt='scroll-logo' />
									<label>desliza</label>
								</section>
							)}
							<section className='sectionAbout'>
								<Formulario1 />
							</section>
							<section className='sectionStamp'>
								<Stamp />
							</section>
							<section className='sectionAbout2'>
								<Formulario2 />
							</section>
							{video[0] && (
								<section className='sectionVideo'>
									<div className='divVideo'>
										{isAuthenticated() && (
											<button className='edit-icon-video' onClick={() => handleEditVideo()}>
												<i className='bi bi-pencil'></i>
											</button>
										)}
										{isEditing ? (
											// Renderizar el campo de entrada y el botón de guardar cuando se está editando
											<div>
												<label htmlFor='url'>URL VIDEO:</label>
												<input
													type='text'
													name='url'
													className='form-control'
													value={newVideo.url}
													onChange={handleVideoInputChange}
												/>
												<label htmlFor='descripcion'>DESCRIPCION VIDEO:</label>
												<input
													type='text'
													name='descripcion'
													className='form-control'
													value={newVideo.descripcion}
													onChange={handleVideoInputChange}
												/>
												<Button className='primary saveVideo' onClick={handleSaveVideo}>
													Guardar
												</Button>
												<Button
													className='secondary cancelEditVideo'
													onClick={handleCancelEditVideo}
												>
													x
												</Button>
											</div>
										) : (
											'' // Mostrar el botón de cerrar cuando no se está editando
										)}
										<YouTube videoId={videoId} opts={opts} />
									</div>

									<div className='textContainer'>
										{video[0]?.descripcion && (
											<ReactMarkdown className='mark'>{video[0]?.descripcion}</ReactMarkdown>
										)}
									</div>
								</section>
							)}

							<GloboContacto />
						</>
					</main>
					<footer>
						<Footer />
					</footer>
				</div>
			)}
		</>
	);
}

export default Home;

import React from 'react';
import StampItem from './StampItem';
import Loader from '../loader/Loader';
import './Stamp.Module.css';
import toast from 'react-hot-toast';
import { getProductos } from '../../utils/productos/productos';

function Stamp() {
	const [loader, setLoader] = React.useState(false);
	const [productos, setProductos] = React.useState([]);

	React.useEffect(() => {
		setLoader(true);
		toast.remove();
		async function getProducts() {
			setProductos(await getProductos());
			setLoader(false);
		}
		getProducts();

		window.addEventListener('actualizar-producto', getProducts);
		return () => {
			window.removeEventListener('actualizar-producto', getProducts);
		};
	}, []);

	return (
		<>
			{loader ? (
				<Loader />
			) : (
				<div className='container'>
					<h6>Clickea y conoce más acerca de nuestras opciones</h6>
					<div className='row row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4 py-5 px-5'>
						{productos.productos?.map((producto, index) => {
							return (
								<div key={index}>
									<StampItem producto={producto} />
								</div>
							);
						})}
					</div>
				</div>
			)}
		</>
	);
}

export default Stamp;

import React from 'react';
import './Stamp.Module.css';
import { Link } from 'react-router-dom';

function StampItem({ producto }) {
	return (
		<div className='px-2 bd-placeholder-img rounded-circle testimonios__img'>
			<Link className='stampLink' to={`/producto/${producto?._id}`}>
				<img
					src={producto?.logo}
					alt='logo de producto'
					className='bd-placeholder-img rounded-circle stamp__img'
				/>
				<h5>{producto?.nombre}</h5>
			</Link>
		</div>
	);
}

export default StampItem;

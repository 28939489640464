import React from 'react';
import NavBar from '../../components/navBar/NavBar';
import Footer from '../../components/footer/Footer';
import GloboContacto from '../../components/globoContacto/GloboContacto';
import { getProductById, getDatos } from '../../utils/productos/productos';
import { useParams } from 'react-router-dom';
import './Detail.Module.css';
import Loader from '../../components/loader/Loader';
import { Toaster, toast } from 'react-hot-toast';
import Carousel from 'react-multi-carousel';
import ReactMarkdown from 'react-markdown';
import { Button } from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css'; // Importa los estilos del carousel
import ModalEditProduct from '../../components/modalEditProduct/ModalEditProduct';
import ModalNewProduct from '../../components/modalNewProduct/ModalNewProduct';
import { isAuthenticated } from '../../utils/usuario/usuario';

export default function Detail() {
	const [producto, setProducto] = React.useState({});
	const [loader, setLoader] = React.useState(true);
	const [showModal, setShowModal] = React.useState(false); // Estado para mostrar/ocultar el modal
	const [showModalNew, setShowModalNew] = React.useState(false); // Estado para mostrar/ocultar el modal
	const { id } = useParams();
	const [datosContacto, setDatosContacto] = React.useState([]);
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};
	React.useEffect(() => {
		async function obtenerDatos() {
			setDatosContacto(await getDatos());
		}
		obtenerDatos();
	}, []);
	React.useEffect(() => {
		toast.remove();
		const getProducto = async () => {
			setProducto(await getProductById(id));
			setLoader(false);
			scrollToTop();
		};
		getProducto();
		window.addEventListener('actualizar-producto', getProducto);
		return () => {
			window.removeEventListener('actualizar-producto', getProducto);
			setProducto({});
		};
	}, [id]);

	// Función para abrir el modal de edición
	const handleOpenModal = () => {
		setShowModal(true);
	};

	// Función para cerrar el modal de edición
	const handleCloseModal = () => {
		setShowModal(false);
	};

	// Función para abrir el modal de edición
	const handleOpenModalNew = () => {
		setShowModalNew(true);
	};

	// Función para cerrar el modal de edición
	const handleCloseModalNew = () => {
		setShowModalNew(false);
	};

	return (
		<div className='fondoDetail' style={{ overflowX: 'hidden' }}>
			<header>
				<NavBar />
			</header>
			<main className='containerDetail py-4'>
				{loader ? (
					<Loader />
				) : (
					<>
						<Toaster
							position='bottom-left'
							reverseOrder={false}
							toastOptions={{ className: 'fondoToast' }}
						/>

						<div className='row featurette containerDetail2'>
							<h3 className='featurette-heading fw-normal lh-1 d-flex justify-content-center'>
								<span className='lead'>{producto?.nombre}</span>
							</h3>

							<div className='detalleProducto'>
								{producto?.imagenes?.length > 0 && (
									<Carousel
										additionalTransfrom={0}
										arrows
										autoPlay
										autoPlaySpeed={2000}
										centerMode={false}
										className=''
										containerClass='carousel-container'
										dotListClass=''
										draggable
										focusOnSelect={false}
										infinite
										itemClass=''
										keyBoardControl
										minimumTouchDrag={80}
										renderButtonGroupOutside={false}
										renderDotsOutside={false}
										sliderClass=''
										slidesToSlide={1}
										ssr
										removeArrowOnDeviceType={['tablet', 'mobile']}
										swipeable
										responsive={{
											// Define las configuraciones de visualización para diferentes tamaños de pantalla
											superLargeDesktop: {
												breakpoint: { max: 4000, min: 3000 },
												items: 1,
											},
											desktop: {
												breakpoint: { max: 3000, min: 1024 },
												items: 1,
											},
											tablet: {
												breakpoint: { max: 1024, min: 464 },
												items: 1,
											},
											mobile: {
												breakpoint: { max: 464, min: 0 },
												items: 1,
											},
										}}
									>
										{/* Contenido del carousel */}
										{producto.imagenes?.map((imagen, index) => (
											<div key={index} className='detalle-item'>
												<div className='detalle-imagen' style={{ flex: '100%' }}>
													<img
														src={imagen.url}
														alt={`Imagen ${index + 1}`}
														style={{ width: '100%' }}
													/>
												</div>
												{/* <div className='detalle-texto' style={{ flex: '50%' }}>
												<p>{imagen.texto}</p>
											</div> */}
											</div>
										))}
									</Carousel>
								)}
							</div>
						</div>
						<ReactMarkdown className='textoDescripcion'>{producto?.descripcion}</ReactMarkdown>
						<Button
							type='submit'
							className='btn btn-secondary py-3 cotizacion'
							href={`https://api.whatsapp.com/send?phone=${datosContacto?.telefono}&text=Hola,%20estoy%20interesado%20en%20${producto?.nombre}`}
							target='_blank'
						>
							Solicitar cotización
						</Button>
						{isAuthenticated() && (
							<div className='buttonProduct'>
								<Button variant='primary' onClick={handleOpenModal}>
									Editar Producto
								</Button>

								<Button variant='primary' onClick={handleOpenModalNew}>
									Nuevo Producto
								</Button>
							</div>
						)}

						<ModalEditProduct show={showModal} handleClose={handleCloseModal} producto={producto} />
						<ModalNewProduct show={showModalNew} handleClose={handleCloseModalNew} />
					</>
				)}
			</main>
			<GloboContacto />
			<footer>
				<Footer />
			</footer>
		</div>
	);
}

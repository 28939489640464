import React from 'react';
import NavBar from '../../components/navBar/NavBar';
import Footer from '../../components/footer/Footer';
import GloboContacto from '../../components/globoContacto/GloboContacto';

import './Nosotros.Module.css';
import CarouselImg from '../../components/carousel/Carousel';
import Loader from '../../components/loader/Loader';

import ReactMarkdown from 'react-markdown';

import 'react-multi-carousel/lib/styles.css'; // Importa los estilos del carousel

export default function Nosotros() {
	const [loader, setLoader] = React.useState(false);

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	React.useEffect(() => {
		setLoader(true);
		setTimeout(() => {
			setLoader(false);
		}, 500);
		scrollToTop();
	}, []);

	// return (
	// 	<div className='fondoDetail' style={{ overflowX: 'hidden' }}>
	// 		<header>
	// 			<NavBar />
	// 		</header>
	// 		{loader ? (
	// 			<Loader />
	// 		) : (
	// 			<>
	// 				<main className='mainAbout py-4'>
	// 					<div className='containerAbout py-4 shadow'>
	// 						<div className='container containerMark'>
	// 							<ReactMarkdown className='aboutText'>
	// 								**JH ENERGÍAS RENOVABLES** es una empresa con una gran experiencia y conocimiento
	// 								en este tipo de energías, con una trayectoria de más de 30 años en el rubro.
	// 							</ReactMarkdown>
	// 							<ReactMarkdown className='aboutText'>
	// 								El objetivo es brindarle al cliente un asesoramiento personalizado y confiable,
	// 								adaptándonos a sus necesidades. Trabajamos para estar a la **vanguardia** de la
	// 								**tecnología** e **innovación**, con la finalidad de proporcionarles los mejores
	// 								productos del mercado, ofreciendo proyectos, ventas, instalaciones y servicios
	// 								postventa.
	// 							</ReactMarkdown>
	// 							<ReactMarkdown className='aboutText'>
	// 								Tenemos la misión de ser la solución sustentable para resolver sus problemas
	// 								energéticos, cuidando el medio ambiente y reduciendo considerablemente los costos
	// 								económicos, e independizando la provisión energética.
	// 							</ReactMarkdown>
	// 						</div>
	// 						<div className='carouselAbout'>
	// 							<CarouselImg />
	// 						</div>
	// 					</div>
	// 				</main>
	// 				<GloboContacto />
	// 				<footer>
	// 					<Footer />
	// 				</footer>
	// 			</>
	// 		)}
	// 	</div>
	// );
	return (
		<div className='fondoDetail' style={{ overflowX: 'hidden' }}>
			<header>
				<NavBar />
			</header>
			{loader ? (
				<Loader />
			) : (
				<>
					<main className='mainAbout py-4'>
						<div className='containerAbout py-4 shadow'>
							{/* <div className='carouselAbout'>
								<CarouselImg />
							</div> */}

							{/* Contenido de texto */}
							<ReactMarkdown className='aboutText'>
								**JH ENERGÍAS RENOVABLES** es una empresa con una gran experiencia y conocimiento en
								este tipo de energías, con una trayectoria de más de 30 años en el rubro.
							</ReactMarkdown>
							<ReactMarkdown className='aboutText'>
								El objetivo es brindarle al cliente un asesoramiento personalizado y confiable,
								adaptándonos a sus necesidades. Trabajamos para estar a la **vanguardia** de la
								**tecnología** e **innovación**, con la finalidad de proporcionarles los mejores
								productos del mercado, ofreciendo proyectos, ventas, instalaciones y servicios
								postventa.
							</ReactMarkdown>
							<ReactMarkdown className='aboutText'>
								Tenemos la misión de ser la solución sustentable para resolver sus problemas
								energéticos, cuidando el medio ambiente y reduciendo considerablemente los costos
								económicos, e independizando la provisión energética.
							</ReactMarkdown>
						</div>
					</main>
					<GloboContacto />
					<footer>
						<Footer />
					</footer>
				</>
			)}
		</div>
	);
}

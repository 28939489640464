import React from 'react';
import loader from '../../assets/LOADER.gif';
import './Loader.Module.css';

export default function Loader() {
	return (
		<div className='loaderContainer'>
			<img className='loader-logo' src={loader} alt='loader-logo' />
		</div>
	);
}

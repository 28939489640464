import React from 'react';
import axios from 'axios';

import { DropdownButton, Dropdown, Navbar, Nav } from 'react-bootstrap';
import { logout, isAuthenticated } from '../../utils/usuario/usuario';

import { Link } from 'react-router-dom';

import logo from '../../assets/imagenes/logo-verde.png';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './NavBar.Module.css';

function NavBar({ tipoUsuario, nombre, puntos }) {
	const idUsuario = localStorage.getItem('Usuario');

	// const uri = process.env.REACT_APP_BACK_URL || 'http://localhost:8080';

	const titleDrop = () => {
		if (isAuthenticated()) {
			return (
				<div className=' dropdowncito '>
					<p className='titleDrop'>ADMIN</p>
				</div>
			);
		} else {
			return '';
		}
	};

	const cerrarSesion = (e) => {
		e.preventDefault();
		window.location.replace('/');
		logout();
	};

	return (
		<div className='navbar navbar-expand-md border-bottom divNavBar' style={{ zIndex: 4000000 }}>
			<Link to='/iniciarsesion' className='login'>
				login
			</Link>
			<Navbar
				// bg='light'
				expand='lg'
				className='container-fluid justify-content-space-between navbaaar'
				style={{ zIndex: 4000000 }}
			>
				<Navbar.Brand href='/' className='logo'>
					<img src={logo} alt='JH energias renovables' />
				</Navbar.Brand>
				<Navbar.Toggle className='custom-toggle-image' aria-controls='basic-navbar-nav' />
				<Navbar.Collapse id='basic-navbar-nav'>
					<Nav className='ml-auto nav nav-pills navbar-nav me-auto mb-2 mb-md-0 derecha'>
						<section className='containerNavLinks'>
							<Link to='/' className='nav-link linkNavBar'>
								INICIO
							</Link>
							{/* <Link to='/catalogo' className='nav-link linkNavBar'>
								Market
							</Link> */}

							<Link to='/nosotros' className='nav-link linkNavBar'>
								NOSOTROS
							</Link>
							<Link to='/contacto' className='nav-link linkNavBar'>
								CONTACTO
							</Link>
							{isAuthenticated() && (
								<Link onClick={(e) => cerrarSesion(e)} className='nav-link linkNavBar cerrar'>
									CERRAR SESION
								</Link>
							)}
						</section>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	);
}

export default NavBar;

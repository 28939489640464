import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import toast from 'react-hot-toast';
import './ModalEditProduct.Module.css';

// Manejo del widget de Cloudinary para subir imágenes
const openCloudinaryWidget = async () => {
	return new Promise((resolve, reject) => {
		try {
			const widget = window.cloudinary.createUploadWidget(
				{
					cloudName: 'jhenergias', // Reemplaza con tu cloudName de Cloudinary
					uploadPreset: 'jhenergias', // Reemplaza con tu uploadPreset de Cloudinary
				},
				(error, result) => {
					if (!error && result && result.event === 'success') {
						resolve(result.info.secure_url); // URL de la imagen subida
					} else {
						reject(error || result);
					}
				}
			);

			widget.open();
		} catch (error) {
			reject(error);
		}
	});
};

const ModalEditProduct = ({ show, handleClose, producto }) => {
	const token = localStorage.getItem('Token');

	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const [formData, setFormData] = useState({
		nombre: producto.nombre,
		estado: producto.estado,
		logo: producto.logo,
		descripcion: producto.descripcion,
		imagenes: producto.imagenes,
	});

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: type === 'checkbox' ? checked : value,
		}));
	};

	function handleOpenWidget(index) {
		try {
			const widgetCloudinary = window.cloudinary.createUploadWidget(
				{
					cloudName: 'jhenergias',
					uploadPreset: 'jhenergias',
				},
				async (err, result) => {
					if (!err && result && result.event === 'success') {
						const url = result.info.secure_url;

						const updatedImagenes = [...formData.imagenes];
						updatedImagenes[index].url = url;
						setFormData({ ...formData, imagenes: updatedImagenes });
					}
				}
			);

			widgetCloudinary.open();
		} catch (error) {
			console.log(error);
			//toast.error('Error al cargar la imagen', error);
		}
	}
	function handleOpenWidgetLogo() {
		try {
			const widgetCloudinary = window.cloudinary.createUploadWidget(
				{
					cloudName: 'jhenergias',
					uploadPreset: 'jhenergias',
				},
				async (err, result) => {
					if (!err && result && result.event === 'success') {
						const url = result.info.secure_url;

						setFormData({ ...formData, logo: url });
					}
				}
			);

			widgetCloudinary.open();
		} catch (error) {
			console.log(error);
			//toast.error('Error al cargar la imagen', error);
		}
	}

	const handleSaveChanges = async (data) => {
		try {
			const url = `${process.env.REACT_APP_BACK_URL}/api/productos/${producto?._id}`;

			const response = await axios.put(
				url,
				{
					nombre: data.nombre,
					estado: data.estado,
					logo: data.logo,
					descripcion: data.descripcion,
					imagenes: data.imagenes,
				},
				config
			);

			if (response.status === 201) {
				// Éxito en la actualización
				const event = new Event('actualizar-producto');
				window.dispatchEvent(event);
				const updatedProduct = response.data;
				toast.success('producto actualizado');

				handleClose();
			} else {
				// Error en la actualización
				toast.error('Error al actualizar el producto', response.status);
				console.error('Error al actualizar el producto:', response.status);
			}
		} catch (error) {
			toast.error('Ha ocurrido un error', error);
			console.error('Error en la solicitud PUT:', error);
		}
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Editar Producto</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modalEditProduct'>
				<Form>
					<Form.Group>
						<label htmlFor='nombre'>Nombre:</label>
						<input
							type='text'
							name='nombre'
							className='form-control'
							value={formData.nombre}
							onChange={handleChange}
						/>
					</Form.Group>
					<Form.Group>
						<label htmlFor='estado'>Estado:</label>
						<input
							type='checkbox'
							name='estado'
							className='form-check-input'
							checked={formData.estado}
							onChange={handleChange}
						/>
					</Form.Group>
					<Form.Group>
						<label htmlFor='logo'>Logo (URL de Cloudinary):</label>
						<div>
							<img
								className='imgEdit'
								src={formData.logo}
								alt={`Imagen logo`}
								style={{ width: '150px', height: '150px' }}
							/>
							<br />
							<Button variant='primary' onClick={() => handleOpenWidgetLogo()}>
								Cambiar Logo
							</Button>
						</div>
					</Form.Group>
					<Form.Group>
						<label htmlFor='descripcion'>Descripción (formato markdown):</label>
						<textarea
							name='descripcion'
							className='form-control'
							value={formData.descripcion}
							onChange={handleChange}
						/>
					</Form.Group>
					<Form.Group>
						<label>Imágenes:</label>
						<div className='row row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 py-5 px-5'>
							{formData.imagenes.map((imagen, index) => (
								<div key={index}>
									<img
										className='imgEdit'
										src={imagen.url}
										alt={`Imagen ${index}`}
										style={{ width: '150px', height: '150px' }}
									/>
									<br />
									<Button variant='primary' onClick={() => handleOpenWidget(index)}>
										Cambiar Imagen {index + 1}
									</Button>
								</div>
							))}
						</div>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer className='modalEditProduct'>
				<Button onClick={() => handleSaveChanges(formData)}>Guardar Cambios</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalEditProduct;

import { createBrowserRouter } from 'react-router-dom';
import Home from './routes/home/Home';
import Login from './routes/login/Login';
// import CartDetail from './routes/cart/CartDetail';
// import Catalogue from './routes/catalogue/Catalogue';
import Contact from './routes/contact/Contact';
// import CreateAccount from './routes/login/CreateAccount';
import Detail from './routes/detail/Detail';
import Nosotros from './routes/nosotros/Nosotros';
// import PrevCatalogue from './routes/prevCatalogue/PrevCatalogue';
// import Profile from './routes/profile/Profile';
// import Sale from './routes/sale/Sale';
// import Loader from './components/loader/Loader';
// import Alianzas from './routes/alianzas/Alianzas';
// import RecuperoContraseña from './routes/recuperoContraseña/RecuperoContraseña';
// import { element } from 'prop-types';
// import Dashboard from './routes/dashboard/Dashboard';
// import ModalEditPurchase from './components/modals/modalEditPurchase/ModalEditPurchase';

import Error from './routes/error/Error';

const router = createBrowserRouter([
	{
		path: '/',
		element: <Home />,
		errorElement: <Error />,
	},
	{
		path: '/iniciarsesion',
		element: <Login />,
		errorElement: <Error />,
	},

	{
		path: '/producto/:id',
		element: <Detail />,
		errorElement: <Error />,
	},
	{
		path: '/contacto',
		element: <Contact />,
		errorElement: <Error />,
	},

	{
		path: '/nosotros',
		element: <Nosotros />,
		errorElement: <Error />,
	},
]);

export default router;

import React from 'react';
import { Link } from 'react-router-dom';
import { isAuthenticated } from '../../utils/usuario/usuario';
import './Formulario2.Module.css';
import axios from 'axios';

export default function Formulario2() {
	const [isInView2, setIsInView2] = React.useState(false);
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};

	const [loader, setLoader] = React.useState(true);
	const [image, setImage] = React.useState('');
	const isSuperAdmin = isAuthenticated();
	const [renderKey, setRenderKey] = React.useState(0);
	const [isEditing, setIsEditing] = React.useState(false);
	const [editedDescription, setEditedDescription] = React.useState('');

	React.useEffect(() => {
		const cargarImagenes = async () => {
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=FORMULARIO2`
			);
			setImage(data.imagenes);
			setLoader(false);
		};
		cargarImagenes();
	}, [renderKey]);

	function handleOpenWidget() {
		const widgetCloudinary = window.cloudinary.createUploadWidget(
			{
				cloudName: 'jhenergias',
				uploadPreset: 'jhenergias',
			},
			async (err, result) => {
				if (!err && result && result.event === 'success') {
					const url = result.info.secure_url;

					await axios.put(
						`${process.env.REACT_APP_BACK_URL}/api/imagenes/${image[0]._id}?url=${url}`,
						'',
						config
					);

					setImage({ ...image[0], url });
					setRenderKey((prevKey) => prevKey + 1);
				}
			}
		);

		widgetCloudinary.open();
	}

	function handleDescriptionEdit() {
		setIsEditing(true);
		setEditedDescription(image[0]?.descripcion);
	}

	async function handleSaveDescription() {
		const data = {
			descripcion: editedDescription,
		};
		// Puedes guardar la descripción en el estado local y/o enviarla al backend si lo deseas
		await axios.put(`${process.env.REACT_APP_BACK_URL}/api/imagenes/${image[0]._id}`, data, config);
		setIsEditing(false);
		setImage([{ ...image[0], descripcion: editedDescription }]);
		setRenderKey((prevKey) => prevKey + 1);
		// Tu código para guardar la descripción en el backend si es necesario
	}

	function handleCancelEdit() {
		setIsEditing(false);
	}

	React.useEffect(() => {
		const handleScroll = () => {
			const scrollY = window.scrollY || document.documentElement.scrollTop;
			const windowHeight = window.innerHeight;
			const component = document.querySelector('.container.containAbout2'); // Ajusta el selector según tu estructura

			if (component) {
				const componentRect = component.getBoundingClientRect();
				const componentTop = componentRect.top + scrollY;
				const componentBottom = componentRect.bottom + scrollY;
				const centerY = scrollY + windowHeight / 2;

				// Verifica si el centro vertical del componente está en la vista
				if (centerY >= componentTop && centerY <= componentBottom) {
					setIsInView2(true);
				} else {
					setIsInView2(false);
				}
			}
		};

		window.addEventListener('scroll', handleScroll);

		// Limpia el oyente del evento al desmontar el componente
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [renderKey]);

	return (
		<div className={`container containAbout2 ${isInView2 ? 'in-view2' : ''}`}>
			{image && (
				<div className={`img2 heart-sape ${isInView2 ? 'animate-image2' : ''}`}>
					<img className='imgAbout' src={image[0]?.url} alt='imgJH' />
					{isSuperAdmin && (
						<>
							<div className='edit-icon-about' onClick={() => handleOpenWidget()}>
								<i className='bi bi-pencil'></i>
							</div>
						</>
					)}
				</div>
			)}
			<div className={`text2 ${isInView2 ? 'animate-text2' : ''}`}>
				{isEditing ? (
					<>
						<textarea
							value={editedDescription}
							onChange={(e) => setEditedDescription(e.target.value)}
							rows='4'
							cols='50'
						/>
						<button className='btn btn-sm guardarDesc' onClick={handleSaveDescription}>
							Guardar descripción
						</button>
						{isEditing && (
							<div className='cancel-icon-about edit-icon-aboutText' onClick={handleCancelEdit}>
								<i className='bi bi-x'></i>
							</div>
						)}
					</>
				) : (
					<>
						<h5>{image[0]?.nombre}</h5>
						<p>{image[0]?.descripcion}</p>
						{isSuperAdmin && (
							<div className='edit-icon-aboutText' onClick={handleDescriptionEdit}>
								<i className='bi bi-pencil'></i>
							</div>
						)}
					</>
				)}
				<Link
					className='aboutLik'
					to='https://docs.google.com/forms/d/e/1FAIpQLSdxc_uI4Zz39zvTsEGJICn97Vq_OipJJp1YdZeq68tOIzo8AA/viewform'
					target='blank'
				>
					<button className='btn btn-sm btn-outline-secondary carrito'>COMPLETAR FORMULARIO</button>
				</Link>
			</div>
		</div>
	);
}
